import React from 'react'
import PropTypes from 'prop-types'
import { duration } from 'moment-timezone'
import Icon from 'components/Icon'
import { DAY_START_HOUR } from 'lib/const'
import TimelineExtra from 'components/TimelineExtra'
import TimelineCell from 'components/TimelineCell'

export const SIZE = 80
export const COMPACT_SIZE = 50
export const calculateResolution = hours => {
  // + 1 because of DST
  if (hours <= 24 + 1) {
    return {
      displayShift: true,
      cell: 0.25,
      cellFull: { minutes: 0 },
      label: { time: true, shift: false, weekday: false, date: false }
    }
  } else if (hours <= 24 * 7 + 1) {
    return {
      displayShift: true,
      cell: 1,
      cellFull: { hours: [6, 14, 22], minutes: 0 },
      cellHighlight: { hours: 6, minutes: 0 },
      label: { time: true, shift: true, weekday: true, date: false }
    }
  } else if (hours <= 2 * 24 * 7 + 1) {
    return {
      displayShift: true,
      cell: 2,
      cellFull: { hours: 6, minutes: 0 },
      label: { time: false, shift: false, weekday: false, date: true }
    }
  } else if (hours <= 4 * 24 * 7 + 1) {
    return {
      displayShift: false,
      cell: 4,
      cellFull: { hours: 6, minutes: 0 },
      label: { time: false, shift: false, weekday: false, date: true }
    }
  } else if (hours <= 6 * 24 * 7 + 1) {
    return {
      displayShift: false,
      cell: 12,
      cellFull: { day: 0, hours: 6, minutes: 0 },
      label: { time: false, shift: false, weekday: false, date: true }
    }
  } else {
    return {
      displayShift: false,
      cell: 24,
      cellFull: { day: 0, hours: 6, minutes: 0 },
      label: { time: false, shift: false, weekday: false, date: true }
    }
  }
}

const getStartDate = (date, scope) => {
  switch (scope) {
    case 'day':
      return date
        .clone()
        .hour(DAY_START_HOUR)
        .startOf('hour')
    case 'week':
      return date
        .clone()
        .startOf('isoWeek')
        .hour(DAY_START_HOUR)
    default:
      throw new Error('Bad scope ' + String(scope))
  }
}

const dateMap = (start, finish, resolution, fn) => {
  start = start.clone()
  let result = []

  while (start.isBefore(finish)) {
    result.push(fn(start.clone()))
    start.add(resolution.cell, 'hours')
  }

  return result
}

const checkResolutionCondition = (date, condition) =>
  condition &&
  Object.keys(condition).every(key => {
    const value = condition[key]

    if (value.includes) {
      return value.includes(date[key]())
    } else {
      return value === date[key]()
    }
  })

const extrasFromLunchBreaks = (date, scope, lunchBreaks) => {
  let start = date.clone()
  let current = date.clone()
  let end = start.clone().add(1, scope)
  let result = []

  while (!current.isAfter(end)) {
    lunchBreaks.forEach(
      ({ duration: extraDuration, startsAfterMidnight: extraStart }) => {
        const hours = Math.abs(extraStart / 60)
        const minutes = extraStart % 60
        const extraMoment = current
          .clone()
          .hours(hours)
          .minutes(minutes)
        if (extraMoment.isBefore(end) && extraMoment.isAfter(start)) {
          result.push([
            extraMoment,
            extraMoment.clone().add(extraDuration, 'minutes')
          ])
        }
      }
    )

    current.add(1, 'day')
  }

  return result
}

const Timeline = ({
  scope,
  date,
  header,
  children,
  isLoading,
  size,
  height,
  lunchBreaks,
  overlappingIds,
  Overlap,
  start,
  finish
}) => {
  const timelineStart = start || getStartDate(date, scope)
  const timelineFinish = finish || timelineStart.clone().add(1, scope)
  const hours = duration(timelineFinish.diff(timelineStart)).asHours()
  const resolution = calculateResolution(hours)
  const dateSeries = dateMap(
    timelineStart,
    timelineFinish,
    resolution,
    date => date
  )
  const noneIsFull = dateSeries.every(
    date => !checkResolutionCondition(date, resolution.cellFull)
  )

  return (
    <div className='timeline'>
      {header && (
        <header className='timeline__header' style={{ height: size * height }}>
          {header}
        </header>
      )}
      <div className='timeline__wrapper' style={{ height: size * height }}>
        <div className='timeline__cells'>
          {dateSeries.map((date, idx) => (
            <TimelineCell
              key={date.unix()}
              date={date}
              timelineHours={hours}
              resolution={resolution}
              isFull={
                checkResolutionCondition(date, resolution.cellFull) ||
                (noneIsFull && (idx === 0 || idx === dateSeries.length - 1))
              }
              isHighlight={checkResolutionCondition(
                date,
                resolution.cellHighlight
              )}
            />
          ))}
        </div>
        {extrasFromLunchBreaks(timelineStart, scope, lunchBreaks).map(
          ([from, to]) => (
            <TimelineExtra
              key={from.valueOf()}
              startsAt={from}
              endsAt={to}
              timelineStart={timelineStart}
              timelineHours={hours}
            />
          )
        )}
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            timelineStart,
            timelineHours: hours,
            timelineSize: size,
            timelineHeight: height
          })
        )}
        {Overlap && overlappingIds.map((oid) => (
          <Overlap
            id={oid}
            key={oid}
            timelineStart={timelineStart}
            timelineHours={hours}
            timelineSize={size}
          />
        ))}
      </div>
      {isLoading && (
        <div
          className='timeline__loader'
          style={{ lineHeight: size * height + 'px' }}
        >
          <Icon name='gear' spin />
        </div>
      )}
    </div>
  )
}

Timeline.defaultProps = {
  scope: 'day',
  isLoading: false,
  size: 1,
  height: 80,
  lunchBreaks: []
}

Timeline.propTypes = {
  scope: PropTypes.oneOf(['day', 'week', 'range']),
  start: PropTypes.object,
  finish: PropTypes.object,
  date: PropTypes.object,
  header: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  size: PropTypes.number,
  height: PropTypes.number,
  Overlap: PropTypes.any,
  overlappingIds: PropTypes.arrayOf(PropTypes.string),
  lunchBreaks: PropTypes.arrayOf(
    PropTypes.shape({
      startsAfterMidnight: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired
    })
  )
}

export default Timeline
