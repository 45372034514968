import { selectNavigationRange } from 'containers/Navigation/selectors'

export const selectIsLoading = state => !state.init.isFinished || state.loading
export const selectPlant = state => state.plant
export const selectLocation = state => state.location
export const selectTimezone = state => state.config.timezone
export const selectToken = state => state.config.token
export const selectTimeline = state => state.timeline.timeline
export const selectTimelineSummary = state => state.timeline.summary
export const selectProject = (state, id) => state.projects.allById[id]
export const selectTrend = state => state.trend
export const selectDurations = state => state.durations
export const selectScrap = state => state.scrap
export const selectProjectScope = state => state.project
export const selectProjectScopeId = state => selectProjectScope(state).projectId
export const selectRange = state => {
  const { from, to } = selectProjectScope(state)

  if (from || to) {
    return { from, to }
  } else {
    return selectNavigationRange(state)
  }
}
