import React, { Component, Children } from 'react'
import moment, { duration } from 'moment-timezone'
import PropTypes from 'prop-types'
import TetherComponent from 'react-tether'
import classnames from 'classnames'
import { COLORS } from 'lib/const'

class TimelineBlock extends Component {
  state = {
    isFocused: false
  }

  static defaultProps = {
    color: 'default',
    focused: false,
    size: 1,
    offset: 0,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onClick: () => {},
    stacked: false,
    pointer: false
  }

  static propTypes = {
    timelineStart: PropTypes.object.isRequired,
    timelineHours: PropTypes.number.isRequired,
    timelineSize: PropTypes.number.isRequired,
    timelineHeight: PropTypes.number.isRequired,
    startsAt: PropTypes.object.isRequired,
    endsAt: PropTypes.object.isRequired,
    color: PropTypes.oneOf(COLORS),
    size: PropTypes.number,
    offset: PropTypes.number,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
    stacked: PropTypes.bool,
    pointer: PropTypes.bool,
    significant: PropTypes.bool,
    flash: PropTypes.bool
  }

  focus = e => {
    if (this.focusing) {
      return
    }

    this.focusing = setTimeout(
      () => {
        this.props.onMouseEnter(e)
        this.setState({ isFocused: true })
      },
      50
    )
  }

  blur = e => {
    clearTimeout(this.focusing)
    delete this.focusing
    this.props.onMouseLeave(e)
    this.setState({ isFocused: false })
  }

  componentWillUnmount () {
    if (this.focusing) {
      clearTimeout(this.focusing)
      delete this.focusing
    }
  }

  render () {
    const {
      startsAt,
      endsAt,
      timelineStart,
      color,
      timelineHours: hours,
      children,
      size,
      offset,
      timelineSize,
      timelineHeight,
      stacked,
      pointer,
      significant,
      flash
    } = this.props
    const isFocused = this.state.isFocused
    const timelineEnd = timelineStart.clone().add(hours, 'hours')
    const visibleStartsAt = moment.max(startsAt, timelineStart)
    const visibleEndsAt = moment.min(endsAt, timelineEnd)
    const extraBefore = !visibleStartsAt.isSame(startsAt)
    const extraAfter = !visibleEndsAt.isSame(endsAt)
    const width = 100 * duration(visibleEndsAt.diff(visibleStartsAt)).asHours() / hours
    const leftOffset = 100 * duration(visibleStartsAt.diff(timelineStart)).asHours() / hours

    if (startsAt.isAfter(timelineEnd) || endsAt.isBefore(timelineStart)) {
      return null
    } else {
      return (
        <div
          className={classnames(
            'timeline-block',
            `timeline-block--${color}`,
            {
              'timeline-block--highlighted': isFocused,
              'timeline-block--extra-before': extraBefore,
              'timeline-block--extra-after': extraAfter,
              'timeline-block--stacked': stacked,
              'timeline-block--pointer': pointer,
              'timeline-block--significant': significant,
              'timeline-block--flash': flash
            }
          )}
          style={{
            position: 'absolute',
            top: offset * timelineHeight,
            bottom: (timelineSize - offset - size) * timelineHeight,
            width: width + '%',
            left: leftOffset + '%'
          }}
          onMouseEnter={this.focus}
          onMouseLeave={this.blur}
          onClick={this.props.onClick}
        >
          <TetherComponent
            attachment='bottom left'
            targetAttachment='top left'
            renderElementTo='#workspace-tether'
            constraints={[
              {
                to: 'window',
                attachment: 'together',
                pin: ['left', 'right']
              }
            ]}
          >
            {
              // Render only first child if not focused
              Children.map(children, (child, i) => {
                if (i > 0 && !isFocused) return
                return child
              })
            }
          </TetherComponent>
        </div>
      )
    }
  }
}

export default TimelineBlock
