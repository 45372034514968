import moment from 'moment-timezone'
import { DAY_START_HOUR, DATE_FORMAT } from 'lib/const'
import { WEEK, MONTH } from 'containers/Navigation/const'

export const selectNavigation = state => (
  {
    ...state.navigation,
    date: state.navigation.date ? state.navigation.date.clone() : state.navigation.date
  }
)
export const selectNavigationScope = state => state.navigation.scope
export const selectNavigationDate = state => selectNavigation(state).date
export const selectNavigationShift = state => state.navigation.shift
export const selectNavigationLength = state => state.navigation.length
export const selectNavigationType = state => state.navigation.type
export const selectNavigationLocation = state => state.navigation.location
export const selectNavigationLocations = state => state.navigation.locations
export const selectNavigationLabelPrefixKey = state => state.navigation.labelPrefixKey

const timeRange = (from, to) => ({
  from: from.clone().hour(DAY_START_HOUR),
  to: to.clone().hour(DAY_START_HOUR).add(1, 'day')
})

const timeRangeForShift = (date, shift) => {
  const shiftOffset = (shift - 1) * 8

  return {
    from: date.clone().hour(DAY_START_HOUR + shiftOffset),
    to: date.clone().hour(DAY_START_HOUR + shiftOffset + 8)
  }
}

export const selectNavigationRange = (state, overrides = {}) => {
  const { date, scope, shift, length } = { ...state.navigation, ...overrides }
  let from, to

  if (scope === MONTH) {
    from = date.clone().startOf('month').subtract(length - 1, 'month')
    to = date.clone().startOf('month').add(1, 'month').subtract(1, 'day')
  } else if (scope === WEEK) {
    from = date.clone().startOf('isoWeek')
    to = date.clone().startOf('isoWeek').add(6, 'days')
  } else {
    from = date.clone().subtract(length - 1, 'day')
    to = date.clone()

    if (shift) {
      return timeRangeForShift(date, shift)
    }
  }

  return timeRange(from, to)
}

export const selectNavigationRangeDates = (state) => {
  const { from, to } = selectNavigationRange(state)
  return {
    from: from.format(DATE_FORMAT),
    to: to.clone().subtract(1, 'day').format(DATE_FORMAT)
  }
}

export const selectIsNavigationInThePast = (state) => {
  const now = moment()
  const range = selectNavigationRange(state)
  return now.isAfter(range.to)
}
