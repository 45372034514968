import React from 'react'
import PropTypes from 'prop-types'
import DateNavigation from 'components/DateNavigation'
import { injectIntl, intlShape } from 'react-intl'
import moment from 'moment'

const formatter = (intl, labelPrefixKey) => (fromDate) => {
  let result = `${intl.formatMessage({ id: 'calendarWeek' }, { number: moment(fromDate).week() })}`

  if (labelPrefixKey) {
    result = `${intl.formatMessage({ id: labelPrefixKey })} ${result}`
  }

  return result
}

const NavigationCweek = ({ labelPrefixKey, date, setDate, children, intl }) => (
  <div className='report-header__navigation'>
    <div className='report-header__navigation-item'>
      <DateNavigation
        date={date}
        scope='week'
        setDate={setDate}
        formatter={formatter(intl, labelPrefixKey)}
      />
    </div>

    {children}
  </div>
)

NavigationCweek.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  labelPrefixKey: PropTypes.string,
  intl: intlShape
}

export default injectIntl(NavigationCweek)
