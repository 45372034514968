import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'

const createStoreLocal = (reducer, initialState, middleware = []) => {
  if (process.env.NODE_ENV === 'development') {
    middleware = [createLogger({ collapsed: true }), ...middleware]
  }

  const store = createStore(
    reducer,
    initialState,
    applyMiddleware(...middleware)
  )

  return store
}

export const createStoreCreator = (reducer) => {
  return (initialState, middleware = []) => createStoreLocal(reducer, initialState, middleware)
}
