import PropTypes from 'prop-types'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const MONTH_FORMAT = 'MMMM YYYY'
export const SIMPLE_DATETIME_FORMAT = 'MM.DD HH:mm'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const COLORS = ['default', 'warning', 'success', 'danger', 'info', 'secondary', 'surprise', 'blue']
export const DAY_START_HOUR = 6
export const NBSP = '\u00A0'

export function withPropType (obj) {
  obj.propType = PropTypes.oneOf(Object.values(obj))
  return obj
}

export const Characters = withPropType({
  PRODUCTION: 'production',
  STOPPAGE: 'stoppage',
  STOPPAGE_PLANNED: 'stoppagePlanned',
  STOPPAGE_UNPLANNED: 'stoppageUnplanned',
  CHANGEOVER: 'changeover',
  SCRAP: 'scrap',
  UNREPORTED: 'unreported'
})
