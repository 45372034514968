import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import TimelineBlock from 'components/TimelineBlock'
import { selectProject } from './selectors'
import { setProject } from './actions'

const DashboardTimelineBlockContent = ({ color, project, value }) => (
  <div
    className={classnames(
      'timeline-block__content',
      `timeline-block__content--${color}`
    )}
    style={{ maxHeight: '25%' }}
  >
    {project}
    <br />
    <strong>
      <code>{value.toFixed(1)}%</code>
    </strong>
  </div>
)

DashboardTimelineBlockContent.defaultProps = {
  color: 'secondary'
}

DashboardTimelineBlockContent.propTypes = {
  color: PropTypes.string,
  project: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
}

const DashboardTimelineBlock = ({
  startedAt,
  finishedAt,
  project,
  oee,
  performance,
  availability,
  technicalAvailability,
  quality,
  setProject,
  ...rest
}) => (
  <TimelineBlock
    startsAt={startedAt}
    endsAt={finishedAt}
    onClick={() => setProject({ projectId: project.id, from: startedAt, to: finishedAt })}
    {...rest}
    stacked
    pointer
    color='secondary'
  >
    <div className='timeline-block__stack'>
      <DashboardTimelineBlockContent
        project={project.name}
        value={oee}
        color='success'
      />
      <DashboardTimelineBlockContent
        project={project.name}
        value={performance}
      />
      <DashboardTimelineBlockContent
        project={project.name}
        value={availability}
      />
      <DashboardTimelineBlockContent
        project={project.name}
        value={technicalAvailability}
      />
      <DashboardTimelineBlockContent project={project.name} value={quality} />
    </div>
  </TimelineBlock>
)

DashboardTimelineBlock.propTypes = {
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  setProject: PropTypes.func
}

const mapStateToProps = (state, { projectId }) => ({
  project: selectProject(state, projectId)
})

const mapDispatchToProps = {
  setProject: setProject
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardTimelineBlock)
