import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { DATE_FORMAT, MONTH_FORMAT } from 'lib/const'
import CustomCalendarInput from 'components/CustomCalendarInput'

class DateNavigation extends Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    scope: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
    setDate: PropTypes.func.isRequired,
    formatter: PropTypes.func
  }

  selectDate = date => this.props.setDate(date)
  navigate = direction => {
    let { date, scope } = this.props
    this.props.setDate(date.add(direction, scope))
  }
  prev = e => this.navigate(-1)
  next = e => this.navigate(1)

  render () {
    const { scope, date,formatter,  ...restProps } = this.props
    let from, to

    if (scope === 'week') {
      from = date.clone().startOf('isoweek')
      to = date.clone().endOf('isoweek')
    }

    if (scope === 'month') {
      from = date.clone().startOf('month')
      to = date.clone().endOf('month')
    }

    return (
      <Fragment>
        <Button color='secondary' onClick={this.prev}>
          <Icon name='caret-left' />
        </Button>

        {scope === 'day' && (
          <DatePicker
            onSelect={this.selectDate}
            selected={date}
            dateFormat={DATE_FORMAT}
            customInput={<CustomCalendarInput formatter={formatter} />}
            {...restProps}
          />
        )}

        {scope === 'week' && (
          <DatePicker
            calendarClassName='react-datepicker--week'
            onSelect={this.selectDate}
            startDate={from}
            endDate={to}
            selected={date}
            dateFormat={DATE_FORMAT}
            customInput={<CustomCalendarInput formatter={formatter} startDate={from} endDate={to} />}
            {...restProps}
          />
        )}

        {scope === 'month' && (
          <DatePicker
            calendarClassName='react-datepicker--month'
            onSelect={this.selectDate}
            startDate={from}
            endDate={to}
            selected={date}
            dateFormat={MONTH_FORMAT}
            customInput={<CustomCalendarInput formatter={formatter} startDate={from} endDate={to} format={MONTH_FORMAT} />}
            {...restProps}
          />
        )}

        <Button color='secondary' onClick={this.next}>
          <Icon name='caret-right' />
        </Button>
      </Fragment>
    )
  }
}

export default DateNavigation
