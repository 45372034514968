import React from 'react'
import PropTypes from 'prop-types'
import NavigationYearToDate from './NavigationYearToDate'
import { Shifts } from 'containers/Navigation/const'
import DropdownButtonSimple from 'components/DropdownButtonSimple'
import Icon from 'components/Icon'
import Button from 'components/Button'

const NavigationYearToDateWithLocation = ({
  date,
  setDate,
  shift,
  setShift,
  location,
  locations,
  setLocation
}) => (
  <NavigationYearToDate
    date={date}
    setDate={setDate}
    shift={shift}
    setShift={setShift}
  >
    <div className='report-header__navigation-item'>
      <DropdownButtonSimple
        color='default'
        style={{ minWidth: 135, textAlign: 'center' }}
        className='btn--flex'
        content={({ close }) => (
          <React.Fragment>
            {locations
              .map((loc, i) => (
                <Button
                  key={loc.id}
                  block
                  disabled={loc.id === location.id}
                  style={{ paddingRight: 35 }}
                  onClick={e => {
                    setLocation(loc)
                    close()
                  }}
                >
                  {loc.name}
                </Button>
              ))}
          </React.Fragment>
        )}
      >
        <span>{location.name}</span>
        <Icon name='caret-down' fw style={{ marginLeft: 3.5 }} />
      </DropdownButtonSimple>
    </div>
  </NavigationYearToDate>
)

const locationShape = PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
NavigationYearToDateWithLocation.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func,
  location: locationShape,
  locations: PropTypes.arrayOf(locationShape),
  setLocation: PropTypes.func
}

export default NavigationYearToDateWithLocation
