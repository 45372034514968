import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { FormattedMessage } from 'react-intl'

class ScopeNavigation extends Component {
  setDayScope = e => this.props.setScope('day')

  render () {
    const { scope, scopeType } = this.props
    const scopeTypes = scopeType.split('_')

    return (
      <Fragment>
        <ToggleButton
          color='default'
          isToggled={scope === 'day'}
          onToggleOn={this.setDayScope}
        >
          <FormattedMessage
            id='components.navigation.daily'
            defaultMessage='Daily'
          />
        </ToggleButton>

        {scopeTypes.includes('week') && (
          <ToggleButton
            color='default'
            isToggled={scope === 'week'}
            onToggleOn={() => this.props.setScope('week')}
          >
            <FormattedMessage
              id='components.navigation.weekly'
              defaultMessage='Weekly'
            />
          </ToggleButton>
        )}
        {scopeTypes.includes('month') && (
          <ToggleButton
            color='default'
            isToggled={scope === 'month'}
            onToggleOn={() => this.props.setScope('month')}
          >
            <FormattedMessage
              id='components.navigation.monthly'
              defaultMessage='Monthly'
            />
          </ToggleButton>
        )}
      </Fragment>
    )
  }
}

ScopeNavigation.propTypes = {
  scope: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  setScope: PropTypes.func.isRequired,
  scopeType: PropTypes.oneOf(['week', 'month', 'week_month']).isRequired
}

export default ScopeNavigation
