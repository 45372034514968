import { handleActions } from 'redux-actions'
import { CONFIGURE_NAVIGATION, SET_NAVIGATION } from './actions'
import {
  TYPE_CWEEK,
  TYPE_WEEK_DAY,
  TYPE_WEEK_DAY_SHIFT,
  TYPE_MONTH_DAY_SHIFT,
  TYPE_MONTHS_TO_DATE,
  TYPE_RANGE,
  TYPE_YEAR_TO_DATE,
  TYPE_YEAR_TO_DATE_WITH_LOCATION,
  DAY,
  TYPE_WEEK_MONTH_DAY_SHIFT,
  TYPE_MONTHLY_RANGE
} from './const'

export default handleActions(
  {
    [CONFIGURE_NAVIGATION]: (state, { payload: type }) => {
      switch (type) {
        case TYPE_CWEEK:
          return {
            date: null,
            ...state,
            type: TYPE_CWEEK,
            scope: 'week',
            shift: null,
            length: 1
          }
        case TYPE_WEEK_DAY:
          return {
            date: null,
            ...state,
            type: TYPE_WEEK_DAY,
            scope: 'week',
            shift: null,
            length: 1
          }
        case TYPE_WEEK_MONTH_DAY_SHIFT:
        case TYPE_MONTH_DAY_SHIFT:
        case TYPE_WEEK_DAY_SHIFT:
          return {
            date: null,
            ...state,
            type,
            scope: 'day',
            shift: null,
            length: 1
          }
        case TYPE_MONTHS_TO_DATE:
          return {
            date: null,
            ...state,
            type: TYPE_MONTHS_TO_DATE,
            scope: 'month',
            shift: null,
            length: 3
          }
        case TYPE_RANGE:
          return {
            date: null,
            ...state,
            type: TYPE_RANGE,
            scope: 'day',
            shift: null,
            length: 7
          }
        case TYPE_MONTHLY_RANGE:
          return {
            date: null,
            ...state,
            type: TYPE_MONTHLY_RANGE,
            scope: 'month',
            length: 12
          }
        case TYPE_YEAR_TO_DATE:
          return {
            date: null,
            ...state,
            type: TYPE_YEAR_TO_DATE,
            scope: 'month',
            shift: null,
            length: 12
          }
        case TYPE_YEAR_TO_DATE_WITH_LOCATION:
          return {
            date: null,
            location: {},
            locations: [],
            ...state,
            type: TYPE_YEAR_TO_DATE_WITH_LOCATION,
            scope: 'month',
            shift: null,
            length: 12
          }
        default:
          return state
      }
    },
    [SET_NAVIGATION]: (state, { payload: changes }) => {
      const date = changes.date ? changes.date.clone() : state.date

      if (
        (changes.scope && changes.scope !== DAY) ||
        (changes.length && changes.length !== 1)
      ) {
        return { ...state, ...changes, date, shift: null }
      } else {
        return { ...state, ...changes, date }
      }
    }
  },
  {
    type: TYPE_WEEK_DAY,
    scope: DAY,
    date: null,
    shift: null,
    location: {},
    locations: [],
    length: 1
  }
)
