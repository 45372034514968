import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { DATE_FORMAT } from 'lib/const'

class CustomCalendarInput extends React.Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    value: PropTypes.string,
    onClick: PropTypes.func,
    buttonProps: PropTypes.object,
    format: PropTypes.string,
    formatter: PropTypes.func
  }

  static defaultProps = {
    buttonProps: {},
    format: DATE_FORMAT
  }

  click = e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onClick(e)
  }

  render () {
    const { startDate, endDate, value, buttonProps, format, formatter } = this.props

    if (formatter) {
      return (
        <Button tag='a' color='default' onClick={this.click} {...buttonProps}>
          <code>
            {formatter(startDate, endDate, value)}
          </code>
        </Button>
      )
    } else if (startDate && endDate && format === DATE_FORMAT) {
      return (
        <Button tag='a' color='default' onClick={this.click} {...buttonProps}>
          <code>
            {startDate.format(format)} &ndash; {endDate.format(format)}
          </code>
        </Button>
      )
    } else if (startDate && endDate) {
      return (
        <Button tag='a' color='default' onClick={this.click} {...buttonProps}>
          <code>{startDate.format(format)}</code>
        </Button>
      )
    } else {
      return (
        <Button tag='a' color='default' onClick={this.click} {...buttonProps}>
          <code>{value}</code>
        </Button>
      )
    }
  }
}

export default CustomCalendarInput
