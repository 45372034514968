import React from 'react'
import { FormattedMessage } from 'react-intl'
import Navigation from 'containers/Navigation/Navigation'
import { connect } from 'react-redux'
import { selectLocation } from 'oee/locationDashboard/selectors'
import PageHeader from 'components/PageHeader'
import Button from '../../components/Button'
import { selectProjectScopeId } from './selectors'
import PropTypes from 'prop-types'
import { setProject } from './actions'
import Icon from '../../components/Icon'

const ReportHeader = ({ name, projectId, setProjectScope }) => {
  return (
    <div className='report-header'>
      <PageHeader title={<h1>{name}</h1>} className='report-header__title' />
      {projectId && (
        <Button
          onClick={() => {
            setProjectScope({})
          }}
          color='info'
        >
          <Icon name='arrow-left' />
          &nbsp;
          <FormattedMessage id='components.button.back' defaultMessage='Back' />
        </Button>
      )}
      {!projectId && <Navigation />}
    </div>
  )
}

ReportHeader.propTypes = {
  name: PropTypes.string,
  projectId: PropTypes.any,
  setProjectScope: PropTypes.func
}

const mapStateToProps = state => ({
  name: selectLocation(state).name,
  projectId: selectProjectScopeId(state)
})

const mapDispatchToProps = {
  setProjectScope: setProject
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportHeader)
