import { pick } from 'lodash'
import { createAction } from 'redux-actions'

export const SET_NAVIGATION = 'SET_NAVIGATION'
export const CONFIGURE_NAVIGATION = 'CONFIGURE_NAVIGATION'

export const setNavigation = createAction(SET_NAVIGATION, changes => pick(changes, ['scope', 'date', 'shift', 'length']))

export const setNavigationScope = createAction(SET_NAVIGATION, scope => ({ scope }))

export const setNavigationDate = createAction(SET_NAVIGATION, date => ({ date }))

export const setNavigationLocation = createAction(SET_NAVIGATION, location => ({ location }))

export const setNavigationLocations = createAction(SET_NAVIGATION, locations => ({ locations, location: locations[0] }))

export const setNavigationShift = createAction(SET_NAVIGATION, shift => ({ shift }))

export const setNavigationLength = createAction(SET_NAVIGATION, length => ({ length }))

export const setNavigationLabelPrefixKey = createAction(SET_NAVIGATION, labelPrefixKey => ({ labelPrefixKey }))

export const configureNavigation = createAction(CONFIGURE_NAVIGATION)
