import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TimelineLabel from 'components/TimelineLabel'

const SHIFT_START_HOURS = [6, 14, 22]

const TimelineCell = ({ date, timelineHours: hours, resolution, isFull, isHighlight }) => {
  const isShift = resolution.displayShift && SHIFT_START_HOURS.includes(date.hours()) && date.minutes() === 0
  const width = `${(resolution.cell * 100) / hours}%`

  const css = classnames(
    isFull ? 'timeline-cell' : 'timeline-cell--half',
    isHighlight && 'timeline-cell--highlight',
    { 'timeline-cell--shift': isShift }
  )

  return (
    <div className={css} style={{ width }}>
      {
        isFull
          ? (
            <TimelineLabel
              date={date}
              showTime={resolution.label.time}
              showShift={resolution.label.shift}
              showWeekday={resolution.label.weekday}
              showDate={resolution.label.date}
            />
          )
          : ''
      }
    </div>
  )
}

TimelineCell.propTypes = {
  date: PropTypes.object,
  timelineHours: PropTypes.number,
  resolution: PropTypes.shape({
    cell: PropTypes.number.isRequired,
    cellFull: PropTypes.object.isRequired,
    displayShift: PropTypes.boolean
  }).isRequired,
  isHighlight: PropTypes.bool,
  isFull: PropTypes.bool
}

TimelineCell.defaultProps = {
  isHighlight: false,
  isFull: false
}

export default TimelineCell
