import React from 'react'
import PropTypes from 'prop-types'
import DateNavigation from 'components/DateNavigation'
import ScopeNavigation from 'components/ScopeNavigation'
import ShiftNavigation from 'components/ShiftNavigation'
import { DAY, WEEK, TYPE_WEEK_DAY, TYPE_WEEK_DAY_SHIFT, Shifts } from 'containers/Navigation/const'

const NavigationWeekDay = ({ date, setDate, scope, setScope, shift, setShift, type, children }) => (
  <div className='report-header__navigation'>
    <div className='report-header__navigation-item'>
      <DateNavigation date={date} scope={scope} setDate={setDate} />
    </div>
    {
      type === TYPE_WEEK_DAY_SHIFT &&
      <div className='report-header__navigation-item'>
        <ShiftNavigation shift={shift} disabled={scope === 'week'} setShift={setShift} />
      </div>
    }
    <div className='report-header__navigation-item'>
      <ScopeNavigation scope={scope} setScope={setScope} scopeType='week' />
    </div>

    {children}
  </div>
)

NavigationWeekDay.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  scope: PropTypes.oneOf([DAY, WEEK]).isRequired,
  setScope: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func,
  type: PropTypes.oneOf([TYPE_WEEK_DAY, TYPE_WEEK_DAY_SHIFT])
}

export default NavigationWeekDay
