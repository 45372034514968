import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Timeline from 'components/Timeline'
import { selectTimeline, selectIsLoading, selectTimelineSummary, selectRange } from './selectors'
import DashboardTimelineBlock from './DashboardTimelineBlock'

const DashboardTimeline = ({ data, start, finish, loading, summary }) => {
  return (
    <div className='timeline-group timeline-group--flex'>
      <div className='timeline-stack-label'>
        <div className='timeline-stack-label__label timeline-stack-label__label--success'>
          <span className='timeline-stack-label__character'>
            <FormattedMessage id='oee.locationDashboard.oee' defaultMessage='OEE' />
          </span>
          <span className='timeline-stack-label__percentage'>{summary.oee && summary.oee.toFixed(1)}%</span>
        </div>
        <div className='timeline-stack-label__label'>
          <span className='timeline-stack-label__character'>
            <FormattedMessage id='oee.locationDashboard.performance' defaultMessage='Performance' />
          </span>
          <span className='timeline-stack-label__percentage'>{summary.performance && summary.performance.toFixed(1)}%</span>
        </div>
        <div className='timeline-stack-label__label'>
          <span className='timeline-stack-label__character'>
            <FormattedMessage id='oee.locationDashboard.availability' defaultMessage='Availability' />
          </span>
          <span className='timeline-stack-label__percentage'>{summary.availability && summary.availability.toFixed(1)}%</span>
        </div>
        <div className='timeline-stack-label__label'>
          <span className='timeline-stack-label__character'>
            <FormattedMessage id='oee.locationDashboard.technicalAvailability' defaultMessage='Technical Availability' />
          </span>
          <span className='timeline-stack-label__percentage'>{summary.technicalAvailability && summary.technicalAvailability.toFixed(1)}%</span>
        </div>
        <div className='timeline-stack-label__label'>
          <span className='timeline-stack-label__character'>
            <FormattedMessage id='oee.locationDashboard.quality' defaultMessage='Quality' />
          </span>
          <span className='timeline-stack-label__percentage'>{summary.quality && summary.quality.toFixed(1)}%</span>
        </div>
      </div>
      <Timeline scope='range' start={start} finish={finish} isLoading={loading} size={1} height={280}>
        {data.map(row => <DashboardTimelineBlock key={row.key} {...row} />)}
      </Timeline>
    </div>
  )
}

DashboardTimeline.propTypes = {
  data: PropTypes.array.isRequired,
  start: PropTypes.object.isRequired,
  finish: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  loading: PropTypes.bool
}

const mapStateToProps = state => {
  const { from, to } = selectRange(state)
  return {
    start: from,
    finish: to,
    data: selectTimeline(state),
    loading: selectIsLoading(state),
    summary: selectTimelineSummary(state)
  }
}

export default connect(mapStateToProps)(DashboardTimeline)
