import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_PROJECT: 'SET_PROJECT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_LOCATION: 'RECEIVE_LOCATION',
  REQUEST_TIMELINE: 'REQUEST_TIMELINE',
  RECEIVE_TIMELINE: 'RECEIVE_TIMELINE',
  REQUEST_TREND: 'REQUEST_TREND',
  RECEIVE_TREND: 'RECEIVE_TREND',
  REQUEST_DURATIONS: 'REQUEST_DURATIONS',
  RECEIVE_DURATIONS: 'RECEIVE_DURATIONS',
  REQUEST_SCRAP: 'REQUEST_SCRAP',
  RECEIVE_SCRAP: 'RECEIVE_SCRAP',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setProject = createAction(Actions.SET_PROJECT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocation = createAction(Actions.RECEIVE_LOCATION)
export const requestTimeline = createAction(Actions.REQUEST_TIMELINE)
export const receiveTimeline = createAction(Actions.RECEIVE_TIMELINE)
export const requestTrend = createAction(Actions.REQUEST_TREND)
export const receiveTrend = createAction(Actions.RECEIVE_TREND)
export const requestDurations = createAction(Actions.REQUEST_DURATIONS)
export const receiveDurations = createAction(Actions.RECEIVE_DURATIONS)
export const requestScrap = createAction(Actions.REQUEST_SCRAP)
export const receiveScrap = createAction(Actions.RECEIVE_SCRAP)
export const receiveProjects = createAction(Actions.RECEIVE_PROJECTS)
